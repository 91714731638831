<template>
    <div>
        <vue-video :refName="'test1'"></vue-video>
    </div>
</template>
<script>
import vueVideo from './video.vue'
export default {
  components:{
      vueVideo
  }
    
}
</script>